import * as React from "react";

import Puzzle from "../../Puzzle";

import sekrety from "../../images/sekrety.jpg";
import map from "../../images/m1.png";
import audio from "../../../static/a1.mp3";

const Page = () => {
  return (
    <Puzzle
      title="Riddle 1"
      author="Author: Karina Marusińska"
      imageSrc={sekrety}
      imageAlt="potłuczone naczynie"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      // audio={audio}
      language="en"
      languageUrl={"/zagadki/ml8NkbfFdd"}
    >
      <div>
        <p>
        The work refers to children's rituals - "secret games", also known as "views". The game is based on burying carefully composed treasures (flowers, stones, beads, leaves, etc.) in the ground, and then covering them with a piece of glass. The secret of their existence is revealed to friends and relatives as an expression of trust.
        </p>
        <p>
        In traditional examples of "secrets", glass protects the treasures, which become visible after uncovering the layers of the earth (glass protects the treasure and reveals it at the same time). Here, glass is the border between the treasure and the outside world, between what is private and public.

        </p>
        <p>
        In my work entitled Secrets, a fragment of broken glass speaks about violence – it bears traces of skin injuries that are integrated with it. At the same time, it is an oppressive element in itself, e.g. due to the sharp edges. So it could be used as an attack or self-defense tool.
        </p>
        <p>
        The Secrets we look at concern phenomena that belong to a social taboo. Victims of domestic violence usually hide evidence of domestic violence out of shame or fear. Paradoxically, it is accompanied by the hope that someone will discover them and react, ending the suffering of the aggrieved person. It is a work of very small dimensions and a very huge meaning. 

        </p>
        <p>
        Currently, there is a threat of Poland denouncing the Council of Europe Convention (Istanbul Convention) on preventing and combating violence against women and domestic violence. More information on this subject can be found at the following link: https://cpk.org.pl/konwencja-stambulska/.

        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
